
#board {
    box-sizing: border-box;

    display: grid;
    grid-template-rows: 1% repeat(8, 11.5%) 1% 6%;
    grid-template-columns: 6% 1% repeat(8, 11.5%) 1%;
    align-items: center;  /* for the labels */
}

.board-label {
    text-align: center;
}

.board-border {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
}

.board-square {
    width: 100%;
    height: 100%;
    position: relative;  /* to work with children that are position: absolute */
}
.board-top-left-corner { border-radius: 9999px 0 0 0; }
.board-top-right-corner { border-radius: 0 9999px 0 0; }
.board-bottom-right-corner { border-radius: 0 0 9999px 0; }
.board-bottom-left-corner { border-radius: 0 0 0 9999px; }

/*
.board-square.black { background: #80a080; }
.board-square.white { background: #f0f0f0; }
*/

#board.green-theme .board-square.black { background: #80a080; }
#board.green-theme .board-square.white { background: #f0f0f0; }

#board.blue-theme .board-square.black { background: #7baae1; }
#board.blue-theme .board-square.white { background: #f0f0f0; }

#board.red-theme .board-square.black { background: #f07777; }
#board.red-theme .board-square.white { background: #f0f0f0; }

.board-square img {
    width: 100%;
    height: 100%;
    position: absolute;
}


.explosion {
    background: url("/images/explosion.png");
    height: 75px;
    width: 75px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

